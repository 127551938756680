var floatingLabels = function() {
  this.init = function($id) {
    this.inputGroups = document.querySelectorAll($id);

    for(var $i = 0; $i < this.inputGroups.length; $i++) {
      var $inputGroup = this.inputGroups[$i],
          $input = $inputGroup.querySelector('input');
          
          if(!$input) {
            $input = $inputGroup.querySelector('textarea');
          }

      this.addListener($inputGroup, $input);
    }

    return this;
  }

  this.addListener = function($inputGroup, $input) {
    $input.oninput = function() {
      if($input.value != '') {
        if(!$inputGroup.classList.contains('is-floating')){
          $inputGroup.classList.add('is-floating');
        }
      } else {
        if($inputGroup.classList.contains('is-floating')){
          $inputGroup.classList.remove('is-floating');
        }
      }
    }
  }
};