var googleMap = function() {
  this.map = null;
  this.bounds = new google.maps.LatLngBounds();

  this.init = function($id) {
    this.map = new google.maps.Map(document.getElementById($id), {
      center: {
        lat: 50.4956976, 
        lng: 3.3452619
      },
      zoom: 10,
      maxZoom: 15,
      disableDefaultUI: true
    });

    return this;
  };

  this.addMarker = function($data) {
    if(isNaN($data.lat) || isNaN($data.lng)) {
      throw new Error('Lat or Lng is not a number.');
    }

    var $location = new google.maps.LatLng($data.lat, $data.lng);

    var $marker = new google.maps.Marker({
      position: $location,
      title: $data.title,
      map: this.map
    });

    if($data.infoWindow) {
      var $infoWindow = new google.maps.InfoWindow({
        content: $data.infoWindow
      });

      $marker.addListener('click', function() {
        $infoWindow.open(this.map, $marker);
      });
    }

    this.bounds.extend($location);
    this.map.fitBounds(this.bounds);
  };
}