var toggle = function() {
  this.el = null;
  this.toggle = null;
  this.list = null;
  this.state = null;

  this.init = function($id, $options = null) {
    this.el = document.getElementById($id);
    this.toggle = this.el.querySelector('[data-toggle]');
    this.list = this.el.querySelector('[data-list]');
    this.options = $options;
    
    this.addListener({
      toggle: this.toggle,
      list: this.list
    });

    return this;
  }

  this.addListener = function($options) {
    $options.toggle.onclick = function() {
      this.toggleClass({
        toggle: $options.toggle,
        list: $options.list
      });
      return false;
    }.bind(this);
  }

  this.toggleClass = function($options) {
    if(this.state) {
      this.state = null;

      this.el.classList.remove('is-active');
      $options.toggle.classList.remove('is-active');
      $options.list.classList.remove('is-active');

      if(this.options.overflow) {
        document.querySelector('body').style.overflow = null;
      }
    } else {
      this.state = 'is-active';

      this.el.classList.add('is-active');
      $options.toggle.classList.add('is-active');
      $options.list.classList.add('is-active');

      if(this.options.overflow) {
        document.querySelector('body').style.overflow = this.options.overflow;
      }
    }
  }
}

var navLang = new toggle().init('language');
var navMobile = new toggle().init('mobile', {
  overflow: 'hidden'
});